/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Close from "@mui/icons-material/Close";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import {useTheme} from "@mui/material/styles";
import Tab from "@mui/material/Tab";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import Alert from "../../../shared/react/Alert.jsx";
import {parseVersionFromFileName} from "../../../shared/react/FirmwareParsing.js";
import IconWithText from "../../../shared/react/IconWithText.jsx";
import TableLoading from "../../../shared/react/TableLoading.jsx";
import StudyActionsTable from "./StudyActionComponents/StudyActionsTable.jsx";
import AddDowngradeAuthorizationForm from "./StudyActionForms/AddDowngradeAuthorizationForm.jsx";
import ConvertToCemForm from "./StudyActionForms/ConvertToCemForm.jsx";
import EndStudyForm from "./StudyActionForms/EndStudyForm.jsx";
import ForceActionsFailureForm from "./StudyActionForms/ForceActionsFailureForm.jsx";
import MarkStudyAsFailedForm from "./StudyActionForms/MarkStudyAsFailedForm.jsx";
import RemoveDowngradeAuthorizationForm from "./StudyActionForms/RemoveDowngradeAuthorizationForm.jsx";
import ReplaceDeviceForm from "./StudyActionForms/ReplaceDeviceForm.jsx";
import RequestEcgDataForm from "./StudyActionForms/RequestEcgDataForm.jsx";
import ResumeStudyForm from "./StudyActionForms/ResumeStudyForm.jsx";
import SendMessageForm from "./StudyActionForms/SendMessageForm.jsx";
import UpdateSettingsForm from "./StudyActionForms/UpdateSettingsForm.jsx";
import UpdateStartDateForm from "./StudyActionForms/UpdateStartDateForm.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

function StudyActionsDialog({
  // Props
  study,
  action,
  actionTypes,
  Icon,
}) {
  //---------------------------------------------------------------------------
  // Theme
  //---------------------------------------------------------------------------
  const theme = useTheme();

  //---------------------------------------------------------------------------
  // Form and button loading state management
  //---------------------------------------------------------------------------
  const [loadingState, setLoadingState] = React.useState("loaded");

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [pendingActions, setPendingActions] = React.useState([]);
  const [sentActions, setSentActions] = React.useState([]);

  const [enrollmentToSendActionsTo, setEnrollmentToSendActionsTo] = React.useState({
    enrollmentId: study.allEnrollments[0].id,
    deviceId: study.allEnrollments[0].deviceId,
    tzSerial: study.allEnrollments[0].tzSerial,
  });

  const [replaceDeviceActionPending, setReplaceDeviceActionPending] = React.useState(false);
  const [deviceHasBeenReplaced, setDeviceHasBeenReplaced] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(action.name);

  const handleOpen = React.useCallback(() => {
    if (tabValue !== action.name) {
      setTabValue(action.name);
    }
    setOpen(true);
    setLoadingState("loading");
  }, [action.name, tabValue]);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const handleChange = React.useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  //---------------------------------------------------------------------------
  // Helper Functions
  //---------------------------------------------------------------------------
  const enrollmentStartTimeToUse = React.useMemo(() => {
    return study.allEnrollments[study.allEnrollments.length - 1].enrollmentStartTime;
  }, [study]);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  // allEnrollments is sorted on the backend by createdAt, we should have the latest enrollment for the study
  const getActionData = React.useCallback(async () => {
    if (open) {
      // set these variables to false, in case a replace device action was deleted from the pending actions table
      setReplaceDeviceActionPending(false);
      setDeviceHasBeenReplaced(false);

      try {
        const promiseArray = [
          axios({
            url: "/actions",
            method: "get",
            params: {
              deviceId: study.allEnrollments[0].deviceId,
              enrollmentId: {$or: ["null", study.allEnrollments[0].id]}, // Include null enrollmentIds (shown for all enrollments)
              status: "pending",
              order: [["createdAt", "DESC"]],
            },
          }),
          axios({
            url: "/actions",
            method: "get",
            params: {
              deviceId: study.allEnrollments[0].deviceId,
              enrollmentId: {$or: ["null", study.allEnrollments[0].id]}, // Include null enrollmentIds (shown for all enrollments)
              status: "sent",
              order: [["createdAt", "DESC"]],
            },
          }),
        ];

        // this indicates there is a replace device action for this study
        if (study.allEnrollments.length > 1) {
          promiseArray.push(
            axios({
              url: "/actions",
              method: "get",
              params: {
                deviceId: study.allEnrollments[1].deviceId,
                enrollmentId: {$or: ["null", study.allEnrollments[1].id]}, // Include null enrollmentIds (shown for all enrollments)
                status: "pending",
                order: [["createdAt", "DESC"]],
              },
            }),
            axios({
              url: "/actions",
              method: "get",
              params: {
                deviceId: study.allEnrollments[1].deviceId,
                enrollmentId: {$or: ["null", study.allEnrollments[1].id]}, // Include null enrollmentIds (shown for all enrollments)
                status: "sent",
                order: [["createdAt", "DESC"]],
              },
            })
          );
        }

        const [
          newEnrollmentPendingActionsResponse,
          newEnrollmentSentActionsResponse,
          oldEnrollmentPendingActionsResponse,
          oldEnrollmentSentActionsResponse,
        ] = await Promise.all(promiseArray);

        // the last enrollment in the array will be the first enrollment for the study
        let pendingActionsToUse = newEnrollmentPendingActionsResponse.data;
        let sentActionsToUse = newEnrollmentSentActionsResponse.data;
        // this indicates there is a replace device action for this study
        if (study.allEnrollments.length > 1) {
          // if the newest enrollment has received its scp0, then device has been replaced
          if (study.allEnrollments[0].scpFileReceived) {
            setDeviceHasBeenReplaced(true);
          } else {
            // if the old enrollment has sent its replace device action, use new enrollment for popup
            const sentReplaceDeviceActionForOldEnrollment = oldEnrollmentSentActionsResponse.data.find(
              (anAction) => anAction.name === "replaceDevice"
            );
            if (sentReplaceDeviceActionForOldEnrollment !== undefined) {
              setDeviceHasBeenReplaced(true);
            } else {
              // if the old enrollment has a pending replace device action and the new enrollment has a
              // sent replace device action, use new enrollment for popup
              const pendingReplaceDeviceActionForOldEnrollment =
                oldEnrollmentPendingActionsResponse.data.find(
                  (anAction) => anAction.name === "replaceDevice"
                );

              if (
                pendingReplaceDeviceActionForOldEnrollment !== undefined &&
                newEnrollmentSentActionsResponse.data.some((anAction) => anAction.name === "replaceDevice")
              ) {
                setDeviceHasBeenReplaced(true);
              } else {
                if (pendingReplaceDeviceActionForOldEnrollment) {
                  setReplaceDeviceActionPending(true);
                }

                pendingActionsToUse = oldEnrollmentPendingActionsResponse.data;
                sentActionsToUse = oldEnrollmentSentActionsResponse.data;
                setEnrollmentToSendActionsTo({
                  enrollmentId: study.allEnrollments[1].id,
                  deviceId: study.allEnrollments[1].deviceId,
                  tzSerial: study.allEnrollments[1].tzSerial,
                });
              }
            }
          }
        }

        [...pendingActionsToUse, ...sentActionsToUse].forEach((anAction) => {
          const actionType = anAction.subType || anAction.name;
          if (actionType === "updateFirmware" && anAction.data) {
            anAction.pendingFirmwareVersion = parseVersionFromFileName(anAction.data);
          }
        });

        setPendingActions(pendingActionsToUse);
        setSentActions(sentActionsToUse);
      } catch (err) {
        setError(err.message);
      }

      setLoadingState("loaded");
    }
  }, [open, study]);

  useInterval(getActionData, DATA_REFRESH_INTERVAL_MS, loadingState);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <MenuItem key={action.name} onClick={handleOpen} data-cy={action["data-cy"]}>
        <ListItemIcon>{Icon}</ListItemIcon>
        <ListItemText>{action.readableName}</ListItemText>
      </MenuItem>

      <Dialog open={open} maxWidth="lg" fullWidth data-cy="study-actions-dialog">
        <Alert message={error} setMessage={setError} level="error" />

        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            zIndex: (theTheme) => theTheme.zIndex.drawer + 1,
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>Study Actions</Box>
          <Button color="tertiary" data-cy="close-button" onClick={handleClose}>
            <Close />
          </Button>
        </DialogTitle>

        <Divider sx={{bgcolor: "secondary.main"}} />

        <DialogContent sx={{padding: 0, minHeight: 300}}>
          {loadingState === "loading" && <TableLoading />}
          {loadingState === "reloading" && <LinearProgress color="secondary" />}
          {loadingState !== "loading" && (
            <>
              <TabContext value={tabValue}>
                {replaceDeviceActionPending && (
                  <Alert
                    message={`New actions cannot be sent to ${enrollmentToSendActionsTo.tzSerial} while a Replace Device action is pending`}
                    level="warning"
                  />
                )}

                {!replaceDeviceActionPending && (
                  <Grid container sx={{display: "inline-flex", minHeight: 300}}>
                    <Grid item xs={3} sx={{background: theme.palette.background.gray}}>
                      <TabList
                        onChange={handleChange}
                        orientation="vertical"
                        TabIndicatorProps={{style: {display: "none"}}}
                      >
                        {actionTypes.map((actionType) => (
                          <Tab
                            sx={{
                              alignItems: "flex-start",
                              "&.Mui-selected": {background: theme.palette.background.paper},
                              textAlign: "left",
                            }}
                            key={actionType.name}
                            label={<IconWithText icon={actionType.icon} text={actionType.readableName} />}
                            value={actionType.name}
                            data-cy={`${actionType.name}-tab`}
                          />
                        ))}
                      </TabList>
                    </Grid>

                    <Grid item xs={9}>
                      {/* Tab Panels */}
                      {tabValue === "forceActionsFailure" && (
                        <ForceActionsFailureForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "requestEcgData" && (
                        <RequestEcgDataForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "sendMessage" && (
                        <SendMessageForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "updateStartDate" && (
                        <UpdateStartDateForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "updateSettings" && (
                        <UpdateSettingsForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          enrollmentStartTimeToUse={enrollmentStartTimeToUse}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "replaceDevice" && (
                        <ReplaceDeviceForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          enrollmentStartTimeToUse={enrollmentStartTimeToUse}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "removeDowngradeAuthorization" && (
                        <RemoveDowngradeAuthorizationForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "addDowngradeAuthorization" && (
                        <AddDowngradeAuthorizationForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "endStudy" && (
                        <EndStudyForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "resumeStudy" && (
                        <ResumeStudyForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          enrollmentStartTimeToUse={enrollmentStartTimeToUse}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "markStudyAsFailed" && (
                        <MarkStudyAsFailedForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          enrollmentStartTimeToUse={enrollmentStartTimeToUse}
                          handleClose={handleClose}
                        />
                      )}
                      {tabValue === "convertToCem" && (
                        <ConvertToCemForm
                          study={study}
                          enrollmentToUse={enrollmentToSendActionsTo}
                          enrollmentStartTimeToUse={enrollmentStartTimeToUse}
                          handleClose={handleClose}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </TabContext>

              {/* Pending Actions Table */}
              {pendingActions.length > 0 && (
                <StudyActionsTable
                  allActions={[...pendingActions, ...sentActions]}
                  actions={pendingActions}
                  setActions={setPendingActions}
                  study={study}
                  tableType="pending"
                  deviceHasBeenReplaced={deviceHasBeenReplaced}
                  setLoadingState={setLoadingState}
                  setError={setError}
                />
              )}

              {/* Sent Actions Table */}
              {sentActions.length > 0 && (
                <StudyActionsTable
                  actions={sentActions}
                  setActions={setSentActions}
                  study={study}
                  tableType="sent"
                />
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
StudyActionsDialog.propTypes = {
  study: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  actionTypes: PropTypes.array.isRequired,
  Icon: PropTypes.node.isRequired,
};
export default StudyActionsDialog;
