import angular from "angular";

import {angularizeDirective} from "../../../../shared/react-in-angular.js";
import StudyActionsMenuWrapper from "./StudyActionsMenuWrapper.jsx";

export default angular.module("app.dialogs.StudyActionsMenu", []).name;

// matches <br-study-actions-menu>
angularizeDirective(
  StudyActionsMenuWrapper,
  "brStudyActionsMenu",
  angular.module("app.dialogs.StudyActionsMenu"),
  {
    study: "=",
    parent: "=",
  }
);
